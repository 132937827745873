<template>
  <div class="clue_assessment">
    <div class="item1">
      <el-card>
        <div class="item1_item">
          <div class="item1_title">
            <i></i>
            <span>客户决策层参与度</span>
          </div>
          <div class="p_input">
            <p>
              客户决策层（总裁、副总裁、总经理、各部门负责人等）对项目的重视程度以及参与决策的程度，这可以反映项目的真实需求以及潜在的购买力。
            </p>
            <div class="input_souce">
              <el-input
                v-model="queryInfo.oneScore"
                placeholder="0-20分"
                @input="oneScoreCheckInput"
              ></el-input>
              <span>分</span>
            </div>
          </div>
        </div>
        <div class="item1_item">
          <div class="item1_title">
            <i></i>
            <span>项目独特性</span>
          </div>
          <div class="p_input">
            <p>项目是否需要定制化的解决方案，如果具备独特是否有相应匹配服务商，有则满分。</p>
            <div class="input_souce">
              <el-input v-model="queryInfo.twoScore" placeholder="0-15分" @input="twoScoreCheckInput"></el-input>
              <span>分</span>
            </div>
          </div>
        </div>

        <div class="item1_item">
          <div class="item1_title">
            <i></i>
            <span>客户支付能力</span>
          </div>
          <div class="p_input">
            <p>客户的财务状况以及支付能力</p>
            <div class="input_souce">
              <el-input v-model="queryInfo.threeScore" placeholder="0-15分" @input="threeScoreCheckInput"></el-input>
              <span>分</span>
            </div>
          </div>
        </div>
        <div class="item1_item">
          <div class="item1_title">
            <i></i>
            <span>技术创新性</span>
          </div>
          <div class="p_input">
            <p>项目所涉及的技术是否具有创新性，这将影响项目的实施难度以及技术风险。</p>
            <div class="input_souce">
              <el-input v-model="queryInfo.fourScore" placeholder="0-10分" @input="fourScoreCheckInput"></el-input>
              <span>分</span>
            </div>
          </div>
        </div>
        <div class="item1_item">
          <div class="item1_title">
            <i></i>
            <span>项目实施难度</span>
          </div>
          <div class="p_input">
            <p>
              项目实施过程中需要协调多个部门和团队，同时还需要处理一些繁琐的流程和文档工作。这表明项目的实施难度较大，需要我们投入更多的资源来应对可能的风险和问题。
            </p>
            <div class="input_souce">
              <el-input v-model="queryInfo.fiveScore" placeholder="0-10分" @input="fiveScoreCheckInput"></el-input>
              <span>分</span>
            </div>
          </div>
        </div>
        <div class="item1_item">
          <div class="item1_title">
            <i></i>
            <span>市场竞争情况</span>
          </div>
          <div class="p_input">
            <p>
              项目所在市场的竞争非常激烈，是否有企业已经占据了大部分市场份额。这意味着我们需要投入更多的资源来争夺市场份额，并且需要应对可能的价格战等竞争风险。
            </p>
            <div class="input_souce">
              <el-input v-model="queryInfo.sixScore" placeholder="0-10分" @input="sixScoreCheckInput"></el-input>
              <span>分</span>
            </div>
          </div>
        </div>
        <div class="item1_item">
          <div class="item1_title">
            <i></i>
            <span>客户口碑和信誉</span>
          </div>
          <div class="p_input">
            <p>
              客户在行业内有较好的口碑和信誉，这表明客户的回款风险较低，并且有可能在未来的合作中获得更多的机会。
            </p>
            <div class="input_souce">
              <el-input v-model="queryInfo.sevenScore" placeholder="0-5分" @input="sevenScoreCheckInput"></el-input>
              <span>分</span>
            </div>
          </div>
        </div>
        <div class="item1_item">
          <div class="item1_title">
            <i></i>
            <span>项目商务情况</span>
          </div>
          <div class="p_input">
            <p>是否可以触达客户决策层，与其客情关系如何。</p>
            <div class="input_souce">
              <el-input v-model="queryInfo.eightScore" placeholder="0-5分" @input="eightScoreCheckInput"></el-input>
              <span>分</span>
            </div>
          </div>
        </div>
        <div class="item1_item">
          <div class="item1_title">
            <i></i>
            <span>合同条款和条件</span>
          </div>
          <div class="p_input">
            <p>
              合同条款清晰明确，付款方式合理，服务期限和质保期也得到了合理安排。这意味着合同风险较低，并且我们有足够的空间来实现项目的收益。
            </p>
            <div class="input_souce">
              <el-input v-model="queryInfo.nineScore" placeholder="0-5分" @input="nineScoreCheckInput"></el-input>
              <span>分</span>
            </div>
          </div>
        </div>
        <div class="item1_item">
          <div class="item1_title">
            <i></i>
            <span>其他</span>
          </div>
          <div class="p_input">
            <p>项目可能带来品牌推广和市场份额拓展等潜在收益。</p>
            <div class="input_souce">
              <el-input v-model="queryInfo.tenScore" placeholder="0-5分" @input="tenScoreCheckInput"></el-input>
              <span>分</span>
            </div>
          </div>
        </div>

        <div class="footer">
          <el-button type="primary" @click="subimtClick">提交</el-button>
        </div>
      </el-card>
    </div>
    <div class="item2">
      <el-card>
        <div class="item2_top">
          <img :src="rightData.clueImage" alt="" srcset="" />
          <div class="item2_top_right">
            <p>{{ rightData.companyFullName }}</p>
            <div class="tag">
              <el-tag :key="index" v-for="(item, index) in rightData.clueTableList">{{
                item
              }}</el-tag>
            </div>
          </div>
        </div>
        <!-- v-if="queryInfo.updateTime" -->
        <div class="updatae_time" v-if="queryInfo.updateTime">
          <i class="el-icon-refresh-left"></i>
          <span>最近更新时间:{{ queryInfo.updateTime }}</span>
        </div>
        <div class="item2_bottom">
          <div class="item2_bottom_title">
            <i class="one"></i>
            <i class="two"></i>
            <span>商机维度</span>
            <i class="three"></i>
            <i class="four"></i>
          </div>
          <div ref="drawLine" class="item2_bottom_item"></div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { getClueScore, listTopById, updateClueScore, insertClueScore } from '../../../../api/clue.js'
const defaultQueryInfo = Object.freeze({
  clueId: '',
  eightScore: '',
  fiveScore: '',
  fourScore: '',
  nineScore: '',
  oneScore: '',
  sevenScore: '',
  sixScore: '',
  tenScore: '',
  threeScore: '',
  twoScore: ''
})
export default {
  name: 'assessmentOpportunity',
  computed: {
    activeId() {
      return this.$route.query.id
    }
  },
  created() {
    this.getHeadData()
    // 获取数据 看用户是否填写过
    this.getDetail()
  },

  mounted() {
    this.$nextTick(() => {
      this.drawLine()
    })
  },
  data() {
    return {
      foldRefLine: '',
      rightData: {},
      beanData: false, //默认是没有填写
      queryInfo: { ...defaultQueryInfo }
    }
  },
  methods: {
    // 客户决策层参与度 输入框限制
    oneScoreCheckInput(val) {
      const regex = /^([0-9]|1[0-9]|20)?$/
      if (!regex.test(val)) {
        this.queryInfo.oneScore = this.queryInfo.oneScore.substring(0, this.queryInfo.oneScore.length - 1)
      }
    },
    // 项目独特性 输入框限制
    twoScoreCheckInput(val) {
      const num = parseInt(val, 10);  
      if (!isNaN(num) && num >= 0 && num <= 15) {  
        this.queryInfo.twoScore = num.toString();  
      } else {  
        // 如果输入无效，重置到上一个有效值  
        this.queryInfo.twoScore = this.queryInfo.twoScore.substring(0, this.queryInfo.threeScore.length - 1)  
      } 
    },
    // 客户支付能力 输入框限制
    threeScoreCheckInput(val) {
      const num = parseInt(val, 10);  
      if (!isNaN(num) && num >= 0 && num <= 15) {  
        this.queryInfo.threeScore = num.toString();  
      } else {  
        // 如果输入无效，重置到上一个有效值  
        this.queryInfo.threeScore = this.queryInfo.threeScore.substring(0, this.queryInfo.threeScore.length - 1)  
      } 
    },
    // 技术创新性 输入框限制
    fourScoreCheckInput(val) {
      const regex = /^([0-9]|10)?$/
      if (!regex.test(val)) {
        this.queryInfo.fourScore = this.queryInfo.fourScore.substring(0, this.queryInfo.fourScore.length - 1)
      }
    },
    // 项目实施难度 输入框限制
    fiveScoreCheckInput(val) {
      const regex = /^([0-9]|10)?$/
      if (!regex.test(val)) {
        this.queryInfo.fiveScore = this.queryInfo.fiveScore.substring(0, this.queryInfo.fiveScore.length - 1)
      }
    },
    // 市场竞争情况 输入框限制
    sixScoreCheckInput(val) {
      const regex = /^([0-9]|10)?$/
      if (!regex.test(val)) {
        this.queryInfo.sixScore = this.queryInfo.sixScore.substring(0, this.queryInfo.sixScore.length - 1)
      }
    },
    // 客户口碑和信誉 输入框限制
    sevenScoreCheckInput(val) {
      const regex = /^([0-5]|)$/
      if (!regex.test(val)) {
        this.queryInfo.sevenScore = this.queryInfo.sevenScore.substring(0, this.queryInfo.sevenScore.length - 1)
      }
    },
    // 项目商务情况 输入框限制
    eightScoreCheckInput(val) {
      const regex = /^([0-5]|)$/
      if (!regex.test(val)) {
        this.queryInfo.eightScore = this.queryInfo.eightScore.substring(0, this.queryInfo.eightScore.length - 1)
      }
    },
    // 合同条款和条件 输入框限制
    nineScoreCheckInput(val) {
      const regex = /^([0-5]|)$/
      if (!regex.test(val)) {
        this.queryInfo.nineScore = this.queryInfo.nineScore.substring(0, this.queryInfo.nineScore.length - 1)
      }
    },
    // 其他 输入框限制
    tenScoreCheckInput(val) {
      const regex = /^([0-5]|)$/
      if (!regex.test(val)) {
        this.queryInfo.tenScore = this.queryInfo.tenScore.substring(0, this.queryInfo.tenScore.length - 1)
      }
    },
    // 提交
    async subimtClick() {
      this.queryInfo.clueId = this.activeId
      let res
      if (this.beanData) {
        // 编辑
        res = await updateClueScore(this.queryInfo)
      } else {
        // 新增
        res = await insertClueScore(this.queryInfo)
      }
      if (res.data.resultCode === 200) {
        this.$message.success(`${this.beanData ? '编辑' : '新增'}成功`)
        this.drawLine()
        this.$router.go(-1)
      } else {
        this.$message.warning(`${this.beanData ? '编辑' : '新增'}失败`)
      }
    },
    // 画雷达图
    drawLine() {
      const list = [
        this.queryInfo.oneScore || 0,
        this.queryInfo.twoScore || 0,
        this.queryInfo.threeScore || 0,
        this.queryInfo.fourScore || 0,
        this.queryInfo.fiveScore || 0,
        this.queryInfo.sixScore || 0,
        this.queryInfo.sevenScore || 0,
        this.queryInfo.eightScore || 0,
        this.queryInfo.nineScore || 0,
        this.queryInfo.tenScore || 0
      ]
      const option = {
        color: ['#FF7A00'],
        radar: {
          axisName: {
            formatter: '【{value}】',
            color: '#0062FF'
          },
          shape: 'circle',
          indicator: [
            { name: '客户决策层参与度', max: 20 },
            { name: '项目独特性', max: 15 },
            { name: '客户支付能力', max: 15 },
            { name: '技术创新性', max: 10 },
            { name: '项目实施难度', max: 10 },
            { name: '市场竞争情况', max: 10 },
            { name: '客户口碑和信誉', max: 5 },
            { name: '项目商务情况', max: 5 },
            { name: '合同条款和条件', max: 5 },
            { name: '其他', max: 5 }
          ]
        },
        series: [
          {
            type: 'radar',
            tooltip: {
              trigger: 'item'
            },
            data: [
              {
                value: list,
                name: '商机维度',
                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value
                  }
                },
                areaStyle: {
                  color: 'rgba(255, 156, 0, 0.2)'
                }
              }
            ]
          }
        ]
      }

      if (!this.foldRefLine) {
        this.foldRefLine = this.$echarts.init(this.$refs.drawLine)
      }
      this.foldRefLine.setOption(option)
    },
    // 头部数据
    async getHeadData() {
      const { data: res } = await listTopById({
        id: this.activeId
      })
      if (res.resultCode == 200) {
        this.rightData = res.data
      }
    },
    async getDetail() {
      const { data: res } = await getClueScore({ clueId: this.activeId })
      if (res.resultCode === 200 && res.data) {
        this.beanData = true
        this.queryInfo = res.data
        this.drawLine()
      } else {
        this.beanData = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.clue_assessment {
  width: 100%;
  overflow: hidden;
  height: 100%;
  display: flex;
  justify-content: space-between;

  ::v-deep .el-card {
    height: 100%;
    overflow: auto;
  }

  .item1 {
    flex: 1;
    flex-shrink: 0;
    overflow: hidden;
    height: 100%;

    .item1_item {
      width: 100%;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      .item1_title {
        width: 100%;
        display: flex;
        margin: 10px 0;
        display: flex;
        align-items: center;

        i {
          display: block;
          width: 4px;
          height: 20px;
          background-color: #4e93fb;
          margin-right: 4px;
        }

        span {
          color: #333333;
          font-size: 18px;
          font-weight: bold;
        }
      }

      .p_input {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        p {
          margin: 10px 0;
          color: #333333;
          line-height: 20px;
          font-size: 14px;
          font-weight: 400;
        }

        .input_souce {
          display: flex;
          justify-content: space-between;
          align-items: center;

          span {
            color: #333333;
            font-size: 14px;
            font-weight: 400;
            margin-left: 10px;
          }
        }
      }
    }

    .footer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 20px auto;
    }
  }

  .item2 {
    width: 634px;
    height: 100%;
    margin-left: 12px;

    .item2_top {
      width: 100%;
      overflow: hidden;
      display: flex;

      img {
        width: 56px;
        height: 56px;
        border-radius: 4px;
        margin-right: 12px;
      }

      .item2_top_right {
        flex: 1;
        flex-shrink: 0;

        p {
          color: #151515;
          font-size: 24px;
          font-weight: bold;
          margin: 0;
        }

        .tag {
          margin-top: 10px;
          width: 100%;
          ::v-deep .el-tag {
            margin-right: 8px;
            margin-bottom: 10px;
          }
        }
      }
    }

    .updatae_time {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      i {
        color: #0062ff;
        margin-right: 6px;
        font-size: 16px;
      }

      span {
        color: #999999;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .item2_bottom {
      width: 100%;
      overflow: hidden;
      margin-top: 50px;

      .item2_bottom_title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: block;
          width: 84px;
          height: 32px;
          background-color: #4e93fb;
          border-radius: 4px;
          color: #fff;
          font-size: 15px;
          font-weight: 400;
          text-align: center;
          line-height: 32px;
        }

        i {
          background-color: #4e93fb;
        }

        .one {
          width: 2px;
          height: 8px;
          border-radius: 4px;
          margin-right: 2px;
        }
        .two {
          width: 2px;
          height: 14px;
          border-radius: 4px;
          margin-right: 2px;
        }

        .three {
          width: 2px;
          height: 14px;
          border-radius: 4px;
          margin-right: 2px;
          margin-left: 2px;
        }
        .four {
          width: 2px;
          height: 8px;
          border-radius: 4px;
        }
      }

      .item2_bottom_item {
        width: 600px;
        height: 400px;
        margin: 30px auto;
      }
    }
  }
}
</style>
